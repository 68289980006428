import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {DataService} from '../../../services/data/data.service';
import {Solution} from '../../../models/solution';
import {Observable} from 'rxjs';
import {Company} from '../../../models/company';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
    solutions: Solution[];
    company$: Observable<Company>;

    constructor( private titleService: Title,
                 private data: DataService) {
        this.titleService.setTitle('Kilian - Angular 15 Services Page');
    }

    ngOnInit() {
        this.data.loadSolutions().subscribe(filteredSolutions => {
            this.solutions = filteredSolutions;
        });
        this.company$ = this.data.loadCompany();
    }
}
