<!-- Start Marketing Main Banner -->
<div class="main-banner marketing-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>Grow you business startup system better</h1>
                            <p>Our passion to work hard and deliver excellent results. It could solve the needs of your customers and develop innovation.</p>
                            <a routerLink="/contact-us" class="btn btn-primary">Get Started</a>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="marketing-image">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img1.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img3.png" class="wow animate__animated animate__lightSpeedIn" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img4.png" class="wow animate__animated animate__rollIn" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img5.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img6.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img7.png" class="wow animate__animated animate__flip" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img8.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img9.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img10.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img11.png" class="wow animate__animated animate__flip" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/marketing-img12.png" class="wow animate__animated animate__lightSpeedIn" data-wow-delay="0.4s" alt="img">
                            <img src="{{company.baseUrlForAssets}}/img/marketing-shape/main-image.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.4s" alt="img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>

    <!-- Bubble Animte -->
    <div class="bubble-animate">
        <div class="circle small square1"></div>
        <div class="circle small square2"></div>
        <div class="circle small square3"></div>
        <div class="circle small square4"></div>
        <div class="circle small square5"></div>
        <div class="circle medium square1"></div>
        <div class="circle medium square2"></div>
        <div class="circle medium square3"></div>
        <div class="circle medium square4"></div>
        <div class="circle medium square5"></div>
        <div class="circle large square1"></div>
        <div class="circle large square2"></div>
        <div class="circle large square3"></div>
        <div class="circle large square4"></div>
    </div>
</div>
<!-- End Marketing Main Banner -->

<app-partner></app-partner>

<!-- Start Features Area -->
<section class="features-area marketing-features ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box">
                            <img src="{{company.baseUrlForAssets}}/img/payment-img1.png" alt="image">
                            <h3>Keyworter Analysis</h3>
                            <p>Wir bieten eine gründliche Analyse von Schlüsselwörtern an, um sicherzustellen, dass Ihre Webseite für die relevanten Suchanfragen gut positioniert ist</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="{{company.baseUrlForAssets}}/img/payment-img2.png" alt="image">
                            <h3>Wettbewerbsanalyse</h3>
                            <p>Wir analysieren Ihre Wettbewerber und ihre Online-Präsenz, um herauszufinden, welche Maßnahmen Sie ergreifen müssen, um sich von ihnen abzuheben.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box mt-50">
                            <img src="{{company.baseUrlForAssets}}/img/payment-img3.png" alt="image">
                            <h3>PPC Marketing</h3>
                            <p>Wir erstellen und verwalten Ihre Pay-per-Click-Kampagnen, um Ihre Online-Sichtbarkeit und den Traffic auf Ihrer Webseite zu erhöhen</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="{{company.baseUrlForAssets}}/img/payment-img4.png" alt="image">
                            <h3>Web Recherche</h3>
                            <p>Wir führen umfassende Recherchen durch, um Ihnen die relevantesten Informationen für Ihre Online-Strategie zur Verfügung zu stellen.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="features-holder-content">
                    <div class="section-title">
                        <h2>Wir sind hier, um Ihr Produkt bestmöglich zu vermarkten.</h2>
                        <div class="bar"></div>
                        <p>Unser Team aus Experten für Suchmaschinenoptimierung und Marketing analysiert Ihre Zielgruppe und erstellt eine Strategie, die auf Ihre individuellen Bedürfnisse zugeschnitten ist.</p>
                    </div>
                    <p>Unser Team von Experten beherrscht die Kunst, die perfekte Balance zwischen den Leitlinien von Google und Ihren kommerziellen Zielen zu finden. Wir haben innovative Theorien entwickelt, wie man Suchmaschinen als Instrument zur Kundenbindung und nicht nur zur Neukundengewinnung einsetzt. Dabei messen, analysieren und optimieren wir kontinuierlich unsere Strategien, um Ihnen stets den bestmöglichen Service zu bieten.</p>
                    <a routerLink="/services" class="btn btn-primary">Unsere Dienstleistungen</a>
                </div>
            </div>
        </div>
    </div>

    <div class="features-inner-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-ui-call"></i>
                            </div>
                            <h3>Free Caliing Service</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-gift"></i>
                            </div>
                            <h3>Daily Free Gift</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-qr-code"></i>
                            </div>
                            <h3>QR Code Scaner</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="{{company.baseUrlForAssets}}/img/features-img1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="{{company.baseUrlForAssets}}/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="{{company.baseUrlForAssets}}/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Unsere Marketing Dienstleitungen</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pay"></i>
                    </div>
                    <h3>Suchstrategie</h3>
                    <p>Entwickeln Sie eine effektive Suchstrategie, um sicherzustellen, dass Ihre Website von den richtigen Leuten gefunden wird.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-music-note"></i>
                    </div>
                    <h3>Suchmaschinenoptimierung</h3>
                    <p>Optimieren Sie Ihre Website für Suchmaschinen, um Ihre Sichtbarkeit zu verbessern und mehr Traffic zu generieren.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-optic"></i>
                    </div>
                    <h3>Keyword Targeting</h3>
                    <p>Wählen Sie gezielt Keywords aus, um Ihre Zielgruppe zu erreichen und Ihre Suchmaschinen-Rankings zu verbessern.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>Linkaufbau</h3>
                    <p>Erhöhen Sie die Anzahl der Links, die auf Ihre Website verweisen, um Ihre Autorität und Relevanz zu steigern.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-deviantart"></i>
                    </div>
                    <h3>CPA Marketing</h3>
                    <p>Nutzen Sie das Cost-per-Action-Modell, um Ihre Zielgruppe gezielt anzusprechen und Ihre Conversions zu erhöhen.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-pie-alt"></i>
                    </div>
                    <h3>Report Analysis</h3>
                    <p>Analysieren Sie Berichte und Daten, um wertvolle Erkenntnisse zu gewinnen und Ihre Strategie kontinuierlich zu optimieren.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="{{company.baseUrlForAssets}}/img/marketing-1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>Social Media Marketing</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-share-alt"></i>
                    </div>
                    <h3>Email Marketing</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="{{company.baseUrlForAssets}}/img/marketing-2.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="{{company.baseUrlForAssets}}/img/marketing.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>Paid Advertising</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>We provide proffesional staff</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="{{company.baseUrlForAssets}}/img/marketing-3.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="{{company.baseUrlForAssets}}/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="{{company.baseUrlForAssets}}/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->

<!-- Start Join Area -->
<section class="join-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="join-img">
                    <img src="{{company.baseUrlForAssets}}/img/3.png" alt="img">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="join-content">
                    <span>Start 30 Days Free Trial</span>
                    <h3>Overall 400k+ Our Clients! Please Get Start Now</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                    <a routerLink="/contact-us" class="btn btn-primary">Join Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Join Area -->

<!-- Start Analysis Area -->
<section class="analysis-area ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="analysis-form">
                    <h3>Free SEO Analysis</h3>

                    <form>
                        <div class="form-group">
                            <input type="text" placeholder="Name*" class="form-control">
                        </div>

                        <div class="form-group">
                            <input type="email" placeholder="Email*" class="form-control">
                        </div>

                        <div class="form-group">
                            <input type="text" placeholder="Phone*" class="form-control">
                        </div>

                        <div class="form-group">
                            <input type="text" placeholder="Website*" class="form-control">
                        </div>

                        <div class="form-group">
                            <input type="text" placeholder="Company Name*" class="form-control">
                        </div>

                        <button type="submit" class="btn btn-primary">Get Free Report</button>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="analysis-text">
                    <span>Free SEO Analysis</span>
                    <h3>Be on the Top & Get More Traffic to Your Website</h3>
                    <p>The following services explain how we approach SEO for a range of common purposes:</p>
                    <ul>
                        <li>If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                        <li>For businesses which sell products online and and improve their product listings in the search results.</li>
                        <li>If you’re investing in a new website it’s important to ensure it’s built to succeed in the search results too.</li>
                        <li>If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Analysis Area -->

<!-- Start App Funfacts Area -->
<section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-users-alt-5"></i>
                    </div>
                    <h3><span class="count">2150</span>+</h3>
                    <p>Active Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-rocket-alt-1"></i>
                    </div>
                    <h3><span class="count">86</span>%</h3>
                    <p>Success Rate</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-laptop-alt"></i>
                    </div>
                    <h3><span class="count">550</span>+</h3>
                    <p>Projects Done</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-win-trophy"></i>
                    </div>
                    <h3><span class="count">35</span>+</h3>
                    <p>Awards</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Funfacts Area -->

<!-- Start Project Area -->
<section class="project-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Recent Work</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>

    <div class="project-slides owl-carousel owl-theme">
        <div class="single-project">
            <div class="project-image">
                <img src="{{company.baseUrlForAssets}}/img/work-img1.jpg" alt="work">
                <a href="{{company.baseUrlForAssets}}/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="{{company.baseUrlForAssets}}/img/work-img2.jpg" alt="work">
                <a href="{{company.baseUrlForAssets}}/img/work-img2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="{{company.baseUrlForAssets}}/img/work-img3.jpg" alt="work">
                <a href="{{company.baseUrlForAssets}}/img/work-img3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="{{company.baseUrlForAssets}}/img/work-img4.jpg" alt="work">
                <a href="{{company.baseUrlForAssets}}/img/work-img4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="{{company.baseUrlForAssets}}/img/work-img5.jpg" alt="work">
                <a href="{{company.baseUrlForAssets}}/img/work-img5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="{{company.baseUrlForAssets}}/img/work-img6.jpg" alt="work">
                <a href="{{company.baseUrlForAssets}}/img/work-img6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section>
<!-- End Project Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Smart Team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-member-box">
                <img src="{{company.baseUrlForAssets}}/img/team-img1.jpg" alt="team">

                <div class="team-content">
                    <h3>John Doe</h3>
                    <span>CEO</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="{{company.baseUrlForAssets}}/img/team-img2.jpg" alt="team">

                <div class="team-content">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="{{company.baseUrlForAssets}}/img/team-img3.jpg" alt="team">

                <div class="team-content">
                    <h3>David Luiz</h3>
                    <span>Designer</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="{{company.baseUrlForAssets}}/img/team-img4.jpg" alt="team">

                <div class="team-content">
                    <h3>Park Tim</h3>
                    <span>SEO Expert</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="{{company.baseUrlForAssets}}/img/team-img5.jpg" alt="team">

                <div class="team-content">
                    <h3>Doglas Costa</h3>
                    <span>Manager</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Easy Pricing Plans</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Basic</h3>
                        <div class="price-value"><sup>$</sup>59.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Ultra</h3>
                        <div class="price-value"><sup>$</sup>79.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Pro</h3>
                        <div class="price-value"><sup>$</sup>99.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="{{company.baseUrlForAssets}}/img/blog-img1.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 23, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="{{company.baseUrlForAssets}}/img/blog-img2.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Best Marketing Management Tools</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="{{company.baseUrlForAssets}}/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 14, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="{{company.baseUrlForAssets}}/img/blog-img4.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 06, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="{{company.baseUrlForAssets}}/img/blog-img5.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 04, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="{{company.baseUrlForAssets}}/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 26, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="{{company.baseUrlForAssets}}/img/client1.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="{{company.baseUrlForAssets}}/img/client2.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>SEO Expert</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="{{company.baseUrlForAssets}}/img/client3.jpg" alt="client">
                    <h3>Steven John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="{{company.baseUrlForAssets}}/img/client4.jpg" alt="client">
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<!-- Start Get Started Area -->
<section class="get-started ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Get Started Free</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="get-started-form">
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Name">
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email">
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Password">
                </div>

                <button type="submit" class="btn btn-primary">Sign Up</button>
                <p>or use your <a href="#">Facebook Account</a></p>
            </form>
        </div>
    </div>
</section>
<!-- End Get Started Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="{{company.baseUrlForAssets}}/img/mailchimp.png" alt="mailchimp">
                    <h3>Mail Chimp</h3>
                    <span>Send Mail</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="{{company.baseUrlForAssets}}/img/slack.png" alt="mailchimp">
                    <h3>Slack</h3>
                    <span>Messaging</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="{{company.baseUrlForAssets}}/img/twitter.png" alt="mailchimp">
                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="{{company.baseUrlForAssets}}/img/instagram.png" alt="mailchimp">
                    <h3>Instagram</h3>
                    <span>News Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->
