export interface Company {
    id: number;
    name: string;
    about: string;
    baseUrlForAssets: string;
    urlLogo: string;
    urlLogoSmall: string;
    urlInstagram: string;
    urlFacebook: string;
    urlSnapchat: string;
    urlLinkedIn: string;
    urlPinterest: string;
    urlTwitter: string;
    urlGooglePlus: string;
    urlCompany: string;
    infoMail: string;
    phone: string;
    openTimeMoFr: string;
    openTimeSa: string;
    street: string;
    plz: string;
    city: string;
    slogan: string;
    sloganLang: string;

}

export class CompanyImpl implements Company {
    id: number;
    name: string;
    about: string;
    baseUrlForAssets: string;
    city: string;
    infoMail: string;
    openTimeMoFr: string;
    openTimeSa: string;
    phone: string;
    plz: string;
    street: string;
    urlCompany: string;
    urlFacebook: string;
    urlInstagram: string;
    urlLinkedIn: string;
    urlLogo: string;
    urlLogoSmall: string;
    urlPinterest: string;
    urlSnapchat: string;
    urlTwitter: string;
    urlGooglePlus: string;
    slogan: string;
    sloganLang: string;

    static fromJson(json: any): Company {
        const company = new CompanyImpl();
        company.id = json.id;
        company.name = json.name;
        company.about = json.about;
        company.baseUrlForAssets = json.baseUrlForAssets;
        company.urlLogo = json.urlLogo;
        company.urlLogoSmall = json.urlLogoSmall;
        company.urlInstagram = json.urlInstagram;
        company.urlFacebook = json.urlFacebook;
        company.urlSnapchat = json.urlSnapchat;
        company.urlLinkedIn = json.urlLinkedIn;
        company.urlPinterest = json.urlPinterest;
        company.urlTwitter = json.urlTwitter;
        company.urlGooglePlus = json.urlGooglePlus;
        company.urlCompany = json.urlCompany;
        company.infoMail = json.infoMail;
        company.phone = json.phone;
        company.openTimeMoFr = json.openTimeMo_Fr;
        company.openTimeSa = json.openTimeSa;
        company.street = json.street;
        company.plz = json.plz;
        company.city = json.city;
        company.slogan = json.slogan;
        company.sloganLang = json.sloganLang;
        return company;
    }

}

