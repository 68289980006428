import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { Solution, SolutionImpl } from '../../models/solution';
import { Company, CompanyImpl } from '../../models/company';
import { CommonService } from '../common/common.service';
import { Customer, CustomerImpl } from '../../models/costumer';
import { Person, PersonImpl } from '../../models/person';
import { Blog, BlogImpl } from '../../models/blog';
import { Variant, VariantImpl } from '../../models/variant';
import { Webpage, WebpageImpl } from '../../models/webpage';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private company?: Company;
    private solutions?: Solution[];
    private customers?: Customer[];
    private employees?: Person[];
    private blogs?: Blog[];
    private solutionVariants?: Variant[];
    private webpages?: Webpage[];
    private cache = new Map<string, any>();
    private companyInfoUrl = 'assets/file/company.json';

    constructor(private http: HttpClient,
                private common: CommonService) {
    }

    private loadFromUrl<T>(url: string, transformFunc: (json: any) => T): Observable<T> {
        const cachedResponse = this.cache.get(url);
        if (cachedResponse) {
            return of(cachedResponse);
        } else {
            return this.http.get<any>(url).pipe(
                map(json => transformFunc(json)),
                tap(response => this.cache.set(url, response))
            );
        }
    }

    loadCompany(): Observable<Company> {
        if (this.company) {
            return of(this.company);
        } else {
            return this.loadFromUrl(this.companyInfoUrl, CompanyImpl.fromJson);
        }
    }

    loadSolutions(): Observable<Solution[]> {
        if(this.solutions) {
            return of(this.solutions);
        } else {
            //console.log(this.common.solutionsUrl);
            return this.loadFromUrl(this.common.solutionsUrl, jsonArray => jsonArray.map(SolutionImpl.fromJson));
        }
    }


    loadCustomer(): Observable<Customer[]> {
        if (this.customers) {
            return of(this.customers);
        } else {
            return this.loadFromUrl(this.common.customerUrl, jsonArray => jsonArray.map(CustomerImpl.fromJson));
        }
    }

    loadEmployee(): Observable<Person[]> {
        if (this.employees) {
            return of(this.employees);
        }  else {
            return this.loadFromUrl(this.common.employeeUrl, jsonArray => jsonArray.map(PersonImpl.fromJson));
        }
    }

    loadBlogs(): Observable<Blog[]> {
        if (this.blogs) {
            return of(this.blogs);
        } else {
            return this.loadFromUrl(this.common.blogsUrl, jsonArray => jsonArray.map(BlogImpl.fromJson));
        }
    }
    loadSolutionVariant(idName: string): Observable<Variant[]> {
        if (this.solutionVariants) {
            return of(this.solutionVariants);
        } else {
            return this.loadFromUrl(this.common.searchSolutionVariant + idName, jsonArray => jsonArray.map(VariantImpl.fromJson));
        }
    }

     loadAllWebpages(): Observable<Webpage[]> {
            if (this.webpages) {
                return of(this.webpages);
            }  else {
                return this.loadFromUrl(this.common.searchAllWebpages, jsonArray => jsonArray.map(WebpageImpl.fromJson));
            }
        }


    loadSolutionByIdName(idName: string): Observable<Solution> {
        if (this.solutions) {
            const solution = this.solutions.find(s => s.idName === idName);
            if (solution) {
                return of(solution);
            }
        } else {
            return this.loadFromUrl(this.common.searchSolutionWithIdNameUrl + idName, SolutionImpl.fromJson);
        }
    }

}
