import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {filter, map} from 'rxjs/operators';
import {Company} from './models/company';
import {DataService} from './services/data/data.service';
import {Solution} from './models/solution';
import {Observable} from "rxjs";
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    company$: Observable<Company>;
    solutions: Solution[];
    location: any;
    routerSubscription: any;

    constructor(private router: Router,
                private data: DataService) {
    }


    ngOnInit(){
        this.recallJsFuntions();
        this.company$ = this.data.loadCompany();
        this.data.loadSolutions().subscribe(solutions => {
                this.solutions = solutions;
            });
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if ( event instanceof NavigationStart ) {
                    $('.preloader-area').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.preloader-area').fadeOut('slow');
                this.location = this.router.url;
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
}
