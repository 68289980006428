import {Component, Input} from '@angular/core';
import {Company} from '../../../models/company';
import {Customer} from '../../../models/costumer';
import {DataService} from '../../../services/data/data.service';

@Component({
    selector: 'app-feedback-area',
    templateUrl: './feedback-area.component.html',
    styleUrls: ['./feedback-area.component.scss'],
    providers: [DataService]
})
export class FeedbackAreaComponent {
    @Input() company: Company;
    customers: Customer[];

    constructor(
        private data: DataService
    ) {
    }

    ngOnInit() {
        this.data.loadCustomer().subscribe(customer => {
            this.customers = customer;
        });
    }
}
