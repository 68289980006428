import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Company} from '../../../models/company';
import {Observable} from 'rxjs';
import {Blog} from '../../../models/blog';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../services/data/data.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    company$: Observable<Company>;
    blogs$: Observable<Blog[]>;
    selectedBlog$: Observable<Blog>;
    firstFourBlogs$: Observable<Blog[]>;
;
    public previousBlog$: Observable<Blog>;
    public nextBlog$: Observable<Blog>;
    uniqueTags$: Observable<string[]>;
    uniqueCategories$: Observable<string[]>;

    constructor(private titleService: Title,
                private route: ActivatedRoute,
                private data: DataService,
                private meta: Meta) {
        this.titleService.setTitle('Blog Details');
    }

    ngOnInit() {
        this.company$ = this.data.loadCompany();
        // Frage Alle Blogs ab, aber selecte nur einen
        this.blogs$ = this.data.loadBlogs();

        this.firstFourBlogs$ = this.blogs$.pipe(
            map(blogs => blogs.slice(0, 4))
        );

        this.route.paramMap.subscribe(paramMap => {
            const idName = paramMap.get('idName');
            console.log(idName);

            this.selectedBlog$ = this.blogs$.pipe(
                map(blogs => blogs.find(blog => blog.idName === idName))
            );

            this.selectedBlog$.subscribe(blog => {
                if (blog) {
                    this.titleService.setTitle(blog.title);
                    // google keywords
                    this.meta.updateTag({ name: 'keywords', content: blog.keywords });
                    this.meta.updateTag({ name: 'description', content: blog.subtitle});
                }
            });

            this.previousBlog$ = this.blogs$.pipe(
                map(blogs => {
                    let currentBlogIndex = blogs.findIndex(blog => blog.idName === idName);
                    return currentBlogIndex > 0 ? blogs[currentBlogIndex - 1] : null;
                })
            );

            this.nextBlog$ = this.blogs$.pipe(
                map(blogs => {
                    let currentBlogIndex = blogs.findIndex(blog => blog.idName === idName);
                    return currentBlogIndex < blogs.length - 1 ? blogs[currentBlogIndex + 1] : null;
                })
            );
        });

        this.uniqueTags$ = this.blogs$.pipe(
            map(blogs => {
                const tagSet = new Set<string>();
                for (const blog of blogs) {
                    for (const tag of blog.tags) {
                        tagSet.add(tag);
                    }
                }
                return Array.from(tagSet);
            })
        );
        this.uniqueCategories$ = this.blogs$.pipe(
            map(blogs => {
                const categorySet = new Set<string>();
                for (const blog of blogs) {
                    for (const category of blog.categories) {
                        categorySet.add(category);
                    }
                }
                return Array.from(categorySet);
            })
        );
    }
}
