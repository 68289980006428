import { Component } from '@angular/core';
import {Company} from "../../../models/company";
import {Title} from "@angular/platform-browser";
import {DataService} from "../../../services/data/data.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent {
    company$: Observable<Company>;

    constructor( private titleService: Title,
                 private data: DataService,) {
        this.titleService.setTitle('Datenpfleger Kontakt');
    }

    ngOnInit() {
        this.company$ = this.data.loadCompany();
    }

}
