import {Component, Input} from '@angular/core';
import {Company} from '../../../models/company';

@Component({
  selector: 'app-join-area',
  templateUrl: './join-area.component.html',
  styleUrls: ['./join-area.component.scss']
})
export class JoinAreaComponent {
    @Input() company: Company;
}
