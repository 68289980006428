import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {Company} from '../../../models/company';
import {Solution} from '../../../models/solution';
import {DataService} from '../../../services/data/data.service';
import {Tag} from '../../../models/tag';
import {SaasLandingComponent} from '../../pages/saas-landing/saas-landing.component';
import {TagService} from '../../../services/tag/tag.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {
    @Input() company: Company;
    tags: Tag[];
    solutions: Solution[];
    location: any;
    logo = 'logo-white.png';

    constructor(
        private router: Router,
        location: Location,
        private data: DataService,
        private tagService: TagService
    ) { }

    ngOnInit() {
        this.router.events
            .subscribe((event) => {
                if ( event instanceof NavigationEnd ) {
                    this.location = this.router.url;
                    if (this.location === '/digital-marketing') {
                        this.logo = 'logo2.png';
                    } else {
                        this.logo = 'logo-white.png';
                    }
                }
            });

        this.data.loadSolutions().subscribe(solutions => {
            this.solutions = solutions;
        });

    }

    onTagClick(tagIdName: string) {
        this.tagService.setSelectedTag(tagIdName);
    }
}
