<!-- Start Work Process Area -->
<section class="saas-work-process ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Wie ist der Ablauf?</h2>
            <div class="bar"></div>
            <p>Wir halten Sie jederzeit auf dem Laufenden und geben Ihnen regelmässige Einblicke in den Fortschritt unserer Arbeit.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6" *ngFor="let process of workProcess">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="{{process.icon}}"></i>
                    </div>
                    <h3>{{process.title}}</h3>
                    <p>{{process.text}}</p>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Work Process Area -->
