<!-- Start Product Main Banner -->
<div class="main-banner product-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>{{solution.title}}</h1>
                            <p>{{solution.subtitle}}</p>
                            <a href="mailto:{{company.infoMail}}" class="btn btn-primary">Chat</a>
                            <!--<a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a>-->
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="product-image">
                            <img style="max-width: 200%; max-height: 200%;"
                                 src="{{company.baseUrlForAssets}}{{solution.urlTitlePicture}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="offer-badge">10%<span>OFF</span></div>-->

    <div class="creative-shape"><img src="{{company.baseUrlForAssets}}/img/bg4.png" alt="bg"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>
    <div class="shape4 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape4.png" alt="img"></div>
</div>
<!-- End Product Main Banner -->


<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>In welcher Branche ist dein Unternehmen tätig?</p>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <select class="btn btn-primary" id="dropdownDomain"
                            (change)="onDropdownChange($event.target.value)">
                        <option value="all" selected>Alle Branchen</option>
                        <ng-container *ngFor="let domain of domains">
                            <option>{{ domain }}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- choose branche -->


<!-- Start Webpage Area -->
<section class="project-area ptb-100">
    <div class="container">
        <div class="row">

            <div
                *ngFor="let webpage of filteredWebpages | paginate: { itemsPerPage: webpagesPerSite, currentPage: currentPage }"
                class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="{{webpage.imgUrl}}" alt="work">
                        <a href="https://web.datenpfleger.ch/{{webpage.id}}" target="_blank"><i
                            class="icofont-power"></i></a>
                    </div>
                    <div class="project-content">
                            <span>{{webpage.domain}}</span>
                        <h3><a href="https://web.datenpfleger.ch/{{webpage.id}}" target="_blank">{{webpage.name}}</a></h3>
                    </div>
                </div>
            </div>

            <!-- Choose your site -->
            <div class="row mt-4">
                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <nav aria-label="Page navigation example">

                            <ul class="pagination justify-content-center">
                                <li class="page-item" [class.disabled]="currentPage === 1">
                                    <a class="page-link" (click)="onPageChange(currentPage - 1)" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                <li class="page-item" [class.active]="currentPage === page" *ngFor="let page of pages">
                                    <a class="page-link" (click)="onPageChange(page)">{{ page }}</a>
                                </li>
                                <li class="page-item" [class.disabled]="currentPage === totalPages">
                                    <a class="page-link" (click)="onPageChange(currentPage + 1)" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>

                        </nav>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Webpage Area -->


<ng-container *ngIf="solutionVariants">
    <app-price-box [solutionVariants]="solutionVariants"></app-price-box>
</ng-container>

<ng-container *ngIf="solution.workProcess">
    <app-work-process-area [workProcess]="solution.workProcess"></app-work-process-area>
</ng-container>


<app-feedback-area [company]="company"></app-feedback-area>

