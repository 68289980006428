<!-- Start Product Main Banner -->
<ng-container *ngIf="(solution$ | async) as solution; else loading">

    <div class="main-banner product-home" *ngIf="(solution$ | async)">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content">
                                <h1>{{(solution$ | async)?.title}}</h1>
                                <p>{{(solution$ | async)?.subtitle}}</p>
                                <a href="mailto:{{company.infoMail}}" class="btn btn-primary">Chat</a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="product-image">
                                <img style="max-width: 200%; max-height: 200%;"
                                     src="{{company.baseUrlForAssets}}{{(solution$ | async)?.urlTitlePicture}}">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="offer-badge">10%<span>OFF</span></div>-->
        <div class="creative-shape"><img src="{{company.baseUrlForAssets}}/img/bg4.png" alt="bg"></div>
        <div class="bg-gray shape-1"></div>
        <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
        <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
        <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
        <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
        <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
        <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>
    </div>
    <!-- End Product Main Banner -->


    <!-- Start Next Generation Area -->
    <section class="next-generation-virtual-reality ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>{{(solution$ | async)?.content[0]}}</h2>
                <div class="bar"></div>
                <p>{{(solution$ | async)?.content[1]}}</p>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6" *ngFor="let include of (solution$ | async)?.includes">
                    <div class="single-item">
                        <div class="icon">
                            <i class="{{include.icon}}"></i>
                        </div>
                        <h3>{{include.title}}</h3>
                        <p>{{include.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Next Generation Area -->


    <ng-container *ngIf="solutionVariants">
        <app-price-box [solutionVariants]="solutionVariants"></app-price-box>
    </ng-container>


    <app-work-process-area [workProcess]="(solution$ | async).workProcess"></app-work-process-area>


    <!--  <app-feedback-area [company]="company"></app-feedback-area>-->


 </ng-container>
 <ng-template #loading>Loading...</ng-template>
