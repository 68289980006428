<ng-container *ngIf="(company$ | async) as company; else loading">
    <ng-container *ngIf="(blogs$ | async) as blogs">
        <ng-container *ngIf="(selectedBlog$ | async) as blog">

            <!-- Start Page Title Area -->
            <section class="page-title-banner">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>Blog Details</h2>
                        </div>
                    </div>
                </div>

                <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
                <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
                <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
                <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
                <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
                <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>

            </section>
            <!-- End Page Title Area -->

            <!-- Start Blog Details Area -->
            <section class="blog-details-area ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="blog-details">
                                <div class="article-img">
                                    <img src="{{company.baseUrlForAssets}}{{blog.pathTitleImage}}" alt="blog-details">
                                    <div class="date">{{ blog.date | date:'dd' }}
                                        <br> {{ blog.date | date:'MMMM':'':'de' }}</div>
                                </div>

                                <div class="article-content">
                                    <ul class="category">
                                        <li *ngFor="let category of blog.categories">
                                            <a [routerLink]="['/blog']"
                                               [queryParams]="{ category: category }">{{category}}</a>
                                        </li>
                                    </ul>
                                    <h1>{{ blog.title }}</h1>

                                    <ng-container *ngFor="let section of blog.postSections | orderBy: 'orderNo'">

                                        <ng-container *ngIf="section.sectionType === 'Title'">
                                            <br>
                                            <h2>{{ section.textContent }}</h2>
                                        </ng-container>

                                        <ng-container *ngIf="section.sectionType === 'Text'">
                                            <p>{{ section.textContent }}</p>
                                        </ng-container>

                                        <ng-container *ngIf="section.sectionType === 'Text important'">
                                            <blockquote class="blockquote">
                                                <p>{{ section.textContent }}</p>
                                            </blockquote>
                                        </ng-container>

                                        <ng-container *ngIf="section.sectionType === 'Image'">
                                            <img src="{{ company.baseUrlForAssets }}{{ section.mediaPath }}"
                                                 alt="blog-details">
                                        </ng-container>

                                        <ng-container *ngIf="section.sectionType === 'HTML'">
                                            <div [innerHTML]="section.textContent"></div>
                                        </ng-container>

                                    </ng-container>
                                    <div class="share-post">
                                        <ul>
                                            <li><a
                                                href="https://www.facebook.com/sharer/sharer.php?u=https://datenpfleger.ch/blog-details/{{ blog.idName }}"
                                                target="_blank"><i class="icofont-facebook"></i></a></li>

                                            <li><a
                                                href="https://twitter.com/intent/tweet?url=https://datenpfleger.ch/blog-details/{{ blog.idName }}"
                                                target="_blank"><i class="icofont-twitter"></i></a></li>
                                            <li><a
                                                href="https://www.linkedin.com/shareArticle?url=https://datenpfleger.ch/blog-details/{{ blog.idName }}"
                                                target="_blank"><i class="icofont-linkedin"></i></a></li>
                                            <li><a
                                                href="https://www.instagram.com/?url=https://datenpfleger.ch/blog-details/{{ blog.idName }}"
                                                target="_blank"><i
                                                class="icofont-instagram"></i></a></li>
                                            <li><a
                                                href="https://vimeo.com/?url=https://datenpfleger.ch/blog-details/{{ blog.idName }}"
                                                target="_blank"><i
                                                class="icofont-vimeo"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="post-controls-buttons">
                                <div class="controls-left" *ngIf="previousBlog$ | async as previousBlog">
                                    <a [routerLink]="['/blog-details', previousBlog.idName]">
                                        <i class="icofont-double-left"></i> Prev Post
                                    </a>
                                </div>

                                <div class="controls-right" *ngIf="nextBlog$ | async as nextBlog">
                                    <a [routerLink]="['/blog-details', nextBlog.idName]">
                                        Next Post <i class="icofont-double-right"></i>
                                    </a>
                                </div>
                            </div>


                            <!-- <div class="post-comments">
                                <h3>Comments</h3>
                                <div class="single-comment">
                                    <div class="comment-img">
                                        <img src="{{company.baseUrlForAssets}}/img/client1.jpg" alt="client">
                                    </div>
                                    <div class="comment-content">
                                        <h4>John Smith</h4>
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe
                                            porro a voluptatem, quidem aut libero consequatur unde molestiae quae
                                            impedit accusantium dolor est corporis! Dolores ut dignissimos
                                            doloribus?</p>
                                        <span>Jan 19, 2018 - 9:10AM</span>
                                        <a routerLink="/blog-details">Reply</a>
                                    </div>
                                </div>

                                <div class="single-comment left-m">
                                    <div class="comment-img">
                                        <img src="{{company.baseUrlForAssets}}/img/client2.jpg" alt="client">
                                    </div>
                                    <div class="comment-content">
                                        <h4>Doe John</h4>
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe
                                            porro a voluptatem, quidem aut libero consequatur unde molestiae quae
                                            impedit accusantium dolor est corporis! Dolores ut dignissimos
                                            doloribus?</p>
                                        <span>Jan 19, 2018 - 9:10AM</span>
                                        <a routerLink="/blog-details">Reply</a>
                                    </div>
                                </div>

                                <div class="single-comment">
                                    <div class="comment-img">
                                        <img src="{{company.baseUrlForAssets}}/img/client3.jpg" alt="client">
                                    </div>
                                    <div class="comment-content">
                                        <h4>Steven Doe</h4>
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe
                                            porro a voluptatem, quidem aut libero consequatur unde molestiae quae
                                            impedit accusantium dolor est corporis! Dolores ut dignissimos
                                            doloribus?</p>
                                        <span>Jan 19, 2018 - 9:10AM</span>
                                        <a routerLink="/blog-details">Reply</a>
                                    </div>
                                </div>

                            </div>-->

                            <!-- <div class="leave-a-reply">
                                <h3>Was meinst Du dazu?</h3>

                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Full Name">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" placeholder="E-Mail">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="comment" cols="30" rows="5" class="form-control"
                                                      placeholder="Your Comment"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>-->
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar-area">

                                <!-- <div class="widget widget-search">
                                    <form>
                                        <input type="text" class="form-control" placeholder="Search">
                                        <button type="submit"><i class="icofont-search-2"></i></button>
                                    </form>
                                </div>-->

                                <div class="widget widget_post_categories">
                                    <h3 class="widget-title">Post Categories</h3>
                                    <div class="bar"></div>
                                    <ul>
                                        <ng-container *ngIf="(uniqueCategories$ | async) as uniqueCategories">
                                            <li *ngFor="let category of uniqueCategories">
                                                <a [routerLink]="['/blog']" [queryParams]="{ category: category }">
                                                    <i class="icofont-bubble-right"></i> {{ category }}
                                                </a>
                                            </li>
                                        </ng-container>

                                    </ul>
                                </div>


                                <div class="widget widget_recent_posts">
                                    <h3 class="widget-title">Weitere Blogs</h3>
                                    <div class="bar"></div>
                                    <ul>
                                        <ng-container *ngIf="firstFourBlogs$ | async as firstFourBlogs">
                                            <li *ngFor="let blog of firstFourBlogs">
                                                <div class="recent-post-thumb">
                                                    <a routerLink="/blog-details/{{blog.idName}}">
                                                        <img [src]="company.baseUrlForAssets + blog.pathTitleImage"
                                                             alt="blog-image">
                                                    </a>
                                                </div>

                                                <div class="recent-post-content">
                                                    <h3><a routerLink="/blog-details/{{blog.idName}}">{{blog.title}}</a>
                                                    </h3>
                                                    <span
                                                        class="date">{{ blog.date | date:'dd MMMM yyyy':'':'de' }}</span>
                                                </div>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>


                                <div class="widget widget_tag_cloud">
                                    <h3 class="widget-title">Popular Tags</h3>
                                    <div class="bar"></div>
                                    <div class="tagcloud">
                                        <a *ngFor="let tag of uniqueTags$ | async" [routerLink]="['/blog']" [queryParams]="{ tag: tag }">{{ tag }}</a>
                                    </div>
                                </div>

                                <!-- <div class="widget widget_text">
                                    <h3 class="widget-title">Instagram</h3>
                                    <div class="bar"></div>
                                    <ul>
                                        <li><a href="#" target="_blank"><img
                                            src="{{company.baseUrlForAssets}}/img/work-img1.jpg" alt="image"></a>
                                        </li>
                                        <li><a href="#" target="_blank"><img
                                            src="{{company.baseUrlForAssets}}/img/work-img2.jpg" alt="image"></a>
                                        </li>
                                        <li><a href="#" target="_blank"><img
                                            src="{{company.baseUrlForAssets}}/img/work-img3.jpg" alt="image"></a>
                                        </li>
                                        <li><a href="#" target="_blank"><img
                                            src="{{company.baseUrlForAssets}}/img/work-img4.jpg" alt="image"></a>
                                        </li>
                                        <li><a href="#" target="_blank"><img
                                            src="{{company.baseUrlForAssets}}/img/work-img5.jpg" alt="image"></a>
                                        </li>
                                        <li><a href="#" target="_blank"><img
                                            src="{{company.baseUrlForAssets}}/img/work-img6.jpg" alt="image"></a>
                                        </li>
                                    </ul>
                                </div>-->

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- End Blog Details Area -->
        </ng-container>
    </ng-container>
</ng-container>
<ng-template #loading>Loading...</ng-template>
