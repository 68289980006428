import { Component } from '@angular/core';

@Component({
  selector: 'app-cta-area',
  templateUrl: './cta-area.component.html',
  styleUrls: ['./cta-area.component.scss']
})
export class CtaAreaComponent {

}
