<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="{{company.baseUrlForAssets}}/img/logo.png" alt="logo2"></a>
                    <p>{{company.sloganLang}}</p>
                       <ul class="social-list">
                         <li *ngIf="company.urlFacebook"><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                         <li *ngIf="company.urlTwitter"><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                         <li *ngIf="company.urlInstagram"><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                         <li *ngIf="company.urlLinkedIn"><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                     </ul>
                </div>
            </div>
                   <div class="col-lg-3 col-md-6">
                   <div class="single-footer-widget pl-5">
                       <h3>Company</h3>

                       <ul class="list">
                           <li><a routerLink="/impressum">Impressum</a></li>
                           <li><a routerLink="/service">Dienstleistungen</a></li>
                           <li><a href="https://datenpfleger.ch/hub/">Cloud Login</a></li>
                       </ul>
                   </div>
               </div>

               <div class="col-lg-3 col-md-6">
                   <div class="single-footer-widget">
                       <h3>Products</h3>

                       <ul class="list">
                           <li><a routerLink="/service/webpage">Webseiten</a></li>
                           <li><a routerLink="/service/support">Support</a></li>
                       </ul>
                   </div>
               </div>

                  <div class="col-lg-3 col-md-6">
                  <div class="single-footer-widget">
                      <h3>Support</h3>

                      <ul class="list">
                          <li><a routerLink="/contact-us">Über uns</a></li>
                          <li><a routerLink="/contact-us">Kontakt</a></li>
                      </ul>
                  </div>
              </div>

        </div>
    </div>

    <div class="copyright-area">
         <div class="container">
             <div class="row h-100 justify-content-center align-items-center">
                 <div class="col-lg-6 col-md-6">
                     <p>&copy;2023 Datenpfleger GmbH. All rights reserved</p>
                 </div>

                 <div class="col-lg-6 col-md-6">
                     <ul>
                         <li><a routerLink="/impressum">Terms & Conditions</a></li>
                         <li><a routerLink="/impressum">Privacy Policy</a></li>
                     </ul>
                 </div>
             </div>
         </div>
     </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->
