import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {DataService} from '../../../services/data/data.service';
import {Company} from '../../../models/company';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    company: Company;

    constructor( private titleService: Title,
                 private data: DataService,) {
        this.titleService.setTitle('Datenpfleger Kontakt');
    }

    ngOnInit() {
        this.data.loadCompany().subscribe(company => {
            this.company = company;
        });
    }

}
