<!-- Start Partner Area -->
<ng-container *ngIf="(company$ | async) as company; else loading">
    <div class="partner-area">
        <div class="container">
            <div class="partner-slides owl-carousel owl-theme">
                <div class="item">
                    <a target="_blank" href="#"><img src="{{company.baseUrlForAssets}}/img/partner/partner-1.png" alt="partner"></a>
                </div>

                <div class="item">
                    <a target="_blank" href="#"><img src="{{company.baseUrlForAssets}}/img/partner/partner-2.png" alt="partner"></a>
                </div>

                <div class="item">
                    <a target="_blank" href="#"><img src="{{company.baseUrlForAssets}}/img/partner/partner-3.png" alt="partner"></a>
                </div>

                <div class="item">
                    <a target="_blank" href="#"><img src="{{company.baseUrlForAssets}}/img/partner/partner-4.png" alt="partner"></a>
                </div>

                <div class="item">
                    <a target="_blank" href="#"><img src="{{company.baseUrlForAssets}}/img/partner/partner-5.png" alt="partner"></a>
                </div>

                <div class="item">
                    <a target="_blank" href="#"><img src="{{company.baseUrlForAssets}}/img/partner/partner-6.png" alt="partner"></a>
                </div>

                <div class="item">
                    <a target="_blank" href="#"><img src="{{company.baseUrlForAssets}}/img/partner/partner-7.png" alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- End Partner Area -->
<ng-template #loading>Loading...</ng-template>
