import {Component, Input} from '@angular/core';
import {Variant} from '../../../models/variant';

@Component({
  selector: 'app-price-box',
  templateUrl: './price-box.component.html',
  styleUrls: ['./price-box.component.scss']
})
export class PriceBoxComponent {
    @Input() solutionVariants: Variant[];

}
