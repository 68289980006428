import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {Company} from '../../../models/company';
import {Observable} from "rxjs";
import {DataService} from "../../../services/data/data.service";

// für carousel
declare var $: any;
@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
    @Input() company$: Observable<Company>;
    // für carousel
    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit() {
        $('.partner-slides').owlCarousel({
            loop: true,
            margin: 10,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 3
                },
                1000: {
                    items: 5
                }
            }
        });
    }



  constructor(
      private data: DataService,
  ) { }

    ngOnInit() {
        this.company$ = this.data.loadCompany();
    }
}
