import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Company } from '../../../models/company';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../../services/data/data.service';
import { Solution } from '../../../models/solution';
import { Variant } from '../../../models/variant';
import {ActivatedRoute, Router} from '@angular/router';
import { Webpage } from '../../../models/webpage';

@Component({
    selector: 'app-webpage',
    templateUrl: './webpage.component.html',
    styleUrls: ['./webpage.component.scss'],
    providers: [DataService],
})
export class WebpageComponent implements OnInit {
    @Input() currentPage: number;
    @Input() totalPages: number;
    @Output() pageChange: EventEmitter<number> = new EventEmitter();

    solution: Solution | undefined;
    webpages: Webpage[] = [];
    filteredWebpages: Webpage[] = []; // gefilterte Webseiten
    solutionVariants: Variant[] = [];
    company: Company | undefined;
    page = 1;
    webpagesPerSite = 12;
    domains: string[] = []; // Liste der Domains aus den Webseiten
    selectedDomain: string | undefined; // ausgewählte Domain im Dropdown-Menü
    isHovered = false;

    constructor(
        private titleService: Title,
        private route: ActivatedRoute,
        private data: DataService,
        private router: Router,
    ) {
        this.titleService.setTitle('Datenpfleger');
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            const domain = params.domain;
            const page = params.page;
            this.selectedDomain = domain;
            this.currentPage = page ? parseInt(page, 10) : 1;
            this.data.loadAllWebpages().subscribe((webpages) => {
                this.webpages = webpages;
                console.log('load webpage');
                webpages.forEach((webpage) => {
                    console.log(webpage.domain);
                });
                // tslint:disable-next-line:max-line-length
                this.domains = Array.from(new Set(webpages.map((webpage) => webpage.domain))); // Liste der eindeutigen Domains aus den Webseiten erstellen
                this.updateFilteredWebpages();
            });
        });

        this.data.loadSolutionByIdName('webpage').subscribe((solution) => (this.solution = solution));
        this.data.loadCompany().subscribe((company) => {
            this.company = company;
        });
    }

    get pages(): number[] {
        const pageArray: number[] = [];
        for (let i = 1; i <= this.totalPages; i++) {
            pageArray.push(i);
        }
        return pageArray;
    }

    onPageChange(pageNumber: number): void {
        this.scrollToTop();
        if (pageNumber >= 1 && pageNumber <= this.totalPages) {
            this.currentPage = pageNumber;
            this.pageChange.emit(pageNumber);

            // Navigiere zur neuen Seite
            this.router.navigate(['/webpage', this.selectedDomain], { queryParams: { ep: this.currentPage } });
        }
    }
    onDropdownChange(selectedValue: any) {
        this.selectedDomain = selectedValue;
        this.filteredWebpages = this.selectedDomain === 'all' ? this.webpages : this.filterWebpages();

        // Setze die aktuelle Seite zurück auf 1, wenn ein neuer Domain-Filter ausgewählt wird
        this.currentPage = 1;

        // Navigiere zur neuen Seite
        this.router.navigate(['/webpage', this.selectedDomain], { queryParams: { ep: this.currentPage } });
    }


    filterWebpages(): Webpage[] {
        if (!this.selectedDomain || this.selectedDomain === 'all') {
            // Wenn keine Domain ausgewählt wurde, alle Webseiten zurückgeben
            this.totalPages = Math.ceil(this.webpages.length / this.webpagesPerSite);
            return this.webpages;
        }
        // Ansonsten die Webseiten nach der ausgewählten Domain filtern
        const filteredWebpages = this.webpages.filter((webpage) => webpage.domain === this.selectedDomain);
        // Total pages berechnen
        this.totalPages = Math.ceil(filteredWebpages.length / this.webpagesPerSite);
        return filteredWebpages;
    }

    updateFilteredWebpages(): void {
        this.filteredWebpages = this.filterWebpages();

        // Total pages berechnen
        this.totalPages = Math.ceil(this.filteredWebpages.length / this.webpagesPerSite);

        // Navigiere zur aktuellen Seite
        this.router.navigate(['/webpage', this.selectedDomain], { queryParams: { ep: this.currentPage } });
    }
    scrollToTop(): void {
        window.scrollTo({
            top: 1200,
            behavior: 'smooth'
        });
    }
}
