import {Component, Input, OnInit} from '@angular/core';
import {Company} from '../../../models/company';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    @Input() company: Company;

  constructor() { }

  ngOnInit() {
  }

}
