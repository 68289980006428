<!-- Start SAAS Tools Area -->
<section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Nichts ist Gratis?<br>Falsch!<br>Mit uns als Geschäftspartner erhalten Sie ...</h2>
            <div class="bar"></div>
            <p>Jedem neuen Geschäftparner bieten wir 3 der folgenden Tools in dem ersten Jahr gratis an!</p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                        <li><a href="#">
                            <i class="icofont-dashboard-web"></i>
                            <br>
                            Dashboard
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-inbox"></i>
                            <br>
                            E-Mail
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-ui-calendar"></i>
                            <br>
                            Calendar
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-copy-invert"></i>
                            <br>
                            Cloud Storage
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-wrench"></i>
                            <br>
                            Support
                        </a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Dashboard</h3>
                                        <p>Überwachen die die Klicks und Views auf Ihrer Webseite</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Überwachung von Klicks</li>
                                            <li><i class="icofont-ui-check"></i> Überwachung von Views</li>
                                            <li><i class="icofont-ui-check"></i> Modernes Design</li>
                                            <li><i class="icofont-ui-check"></i> Jederzeit zugägnlich</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="{{company.baseUrlForAssets}}/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="{{company.baseUrlForAssets}}/img/features-img1.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>E-Mail</h3>
                                        <p>Sichern Sie sich Ihre eigene E-Mail-Adresse mit großzügigem Speicherplatz</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> 5 GB Speicherplatz für E-Mails</li>
                                            <li><i class="icofont-ui-check"></i> Eine eigene E-Mail-Adresse</li>
                                            <li><i class="icofont-ui-check"></i> Zuverlässiger und sicherer E-Mail-Service</li>
                                            <li><i class="icofont-ui-check"></i> Einfach zu verwenden und zu verwalten</li>
                                            <li><i class="icofont-ui-check"></i> Unterstützung für IMAP, POP3 und SMTP-Protokolle</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Kalender</h3>
                                        <p>Behalten Sie Ihre Termine im Blick und synchronisieren Sie sie über verschiedene Geräte hinweg</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Synchronisation über verschiedene Geräte hinweg</li>
                                            <li><i class="icofont-ui-check"></i> Einfache Verwaltung Ihrer Termine</li>
                                            <li><i class="icofont-ui-check"></i> Erinnerungen für anstehende Termine</li>
                                            <li><i class="icofont-ui-check"></i> Möglichkeit, wiederkehrende Termine zu erstellen</li>
                                            <li><i class="icofont-ui-check"></i> Unterstützung für verschiedene Kalenderformate wie iCal und Google Kalender</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="{{company.baseUrlForAssets}}/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="{{company.baseUrlForAssets}}/img/features-img1.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Cloud Storage</h3>
                                        <p>Sichern Sie Ihre Daten in der Cloud und greifen Sie von überall darauf zu</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> 10 GB Speicherplatz in der Cloud</li>
                                            <li><i class="icofont-ui-check"></i> Unbegrenzte Anzahl von Benutzern</li>
                                            <li><i class="icofont-ui-check"></i> Einfache Verwaltung von Dateien und Ordnern</li>
                                            <li><i class="icofont-ui-check"></i> Zuverlässiger und sicherer Cloud-Service</li>
                                            <li><i class="icofont-ui-check"></i> Einfache Freigabe von Dateien und Ordnern mit anderen Benutzern</li>
                                            <li><i class="icofont-ui-check"></i> Unterstützung für verschiedene Dateitypen und -formate</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Support</h3>
                                        <p>Erhalten Sie schnelle Hilfe und Unterstützung bei Fragen und Problemen</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Eine Stunde persönlicher Support</li>
                                            <li><i class="icofont-ui-check"></i> Erfahrene und kompetente Support-Mitarbeiter</li>
                                            <li><i class="icofont-ui-check"></i> Schnelle Reaktionszeiten auf Anfragen</li>
                                            <li><i class="icofont-ui-check"></i> Hilfe bei Fragen zur Nutzung der Webseite oder der Produkte</li>
                                            <li><i class="icofont-ui-check"></i> Unterstützung bei der Behebung technischer Probleme</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="{{company.baseUrlForAssets}}/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
</section>
<!-- End SAAS Tools Area -->
