import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {
    transform(value: any[]): any[] {
        // Verwendet ein Set, um nur eindeutige Werte beizubehalten
        const uniqueValues = new Set();

        // Durchläuft das Array und fügt nur eindeutige Werte zum Set hinzu
        value.forEach(item => uniqueValues.add(item));

        // Konvertiert das Set zurück in ein Array und gibt es zurück
        return Array.from(uniqueValues);
    }
}
