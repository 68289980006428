export class Variant {
    title: string;
    idService: string;
    idProject: string;
    text: string;
    content: string[];
    price: number;
    priceCharacteristics: string;
    url: string;
}

export class VariantImpl implements Variant {
    title: string;
    idService: string;
    idProject: string;
    text: string;
    content: string[];
    price: number;
    priceCharacteristics: string;
    url: string;


    static fromJson(json: any): Variant {
        const variant = new Variant();
        variant.idService = json.idService;
        variant.idProject = json.idProject;
        variant.text = json.text;
        variant.content = JSON.parse(json.content);
        variant.price = json.price;
        variant.priceCharacteristics = json.priceCharacteristics;
        variant.url = json.url;
        variant.title = json.title;
        return variant;
    }

}
