export class Person {
    firstName: string;
    lastName: string;
    gender: string;
    email: string;
    jobTitle: string;
    bio: string;
    urlPicture: string;
    urlInstagram: string;
    urlFacebook: string;
    urlTwitter: string;
    urlLinkedIN: string;

}

export class PersonImpl implements Person {
    firstName: string;
    lastName: string;
    gender: string;
    email: string;
    jobTitle: string;
    bio: string;
    urlPicture: string;
    urlInstagram: string;
    urlFacebook: string;
    urlTwitter: string;
    urlLinkedIN: string;

    static fromJson(json: any): Person {
        const person = new Person();
        person.firstName = json.firstName;
        person.lastName = json.lastName;
        person.gender = json.gender;
        person.email = json.email;
        person.jobTitle = json.jobTitle;
        person.bio = json.bio;
        person.urlPicture = json.urlPicture;
        person.urlInstagram = json.urlInstagram;
        person.urlFacebook = json.urlFacebook;
        person.urlTwitter = json.urlTwitter;
        person.urlLinkedIN = json.urlLinkedIN;
        return person;
    }
}
