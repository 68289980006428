<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Our Latest Project</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>    <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Project Area -->
<section class="project-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img1.jpg" alt="work">
                        <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img2.jpg" alt="work">
                        <a href="assets/img/work-img2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img3.jpg" alt="work">
                        <a href="assets/img/work-img3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img4.jpg" alt="work">
                        <a href="assets/img/work-img4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img5.jpg" alt="work">
                        <a href="assets/img/work-img5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img6.jpg" alt="work">
                        <a href="assets/img/work-img6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img1.jpg" alt="work">
                        <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img2.jpg" alt="work">
                        <a href="assets/img/work-img2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/work-img3.jpg" alt="work">
                        <a href="assets/img/work-img3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>

                    <div class="project-content">
                        <span>Digital</span>
                        <h3><a routerLink="/projects">Network Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item"><a class="page-link" routerLink="/projects"><i class="icofont-double-left"></i></a></li>
                            <li class="page-item active"><a class="page-link" routerLink="/projects">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/projects">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/projects">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/projects"><i class="icofont-double-right"></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Area -->
