<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Feedback</h2>
            <div class="bar"></div>
            <p>Was unsere Kunden über uns sagen.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="{{company.baseUrlForAssets}}/img/people/golzary-omid.png" alt="client">
                    <h3>Omid Golzary</h3>
                    <span>Head of ABS Autoservice</span>
                </div>
                <p>Wir sind sehr zufrieden mit der schnellen und unkomplizierten Installation des Überwachungssystems durch Datenpfleger. Besonders hervorzuheben ist das gute Engineering bei der Auswahl des passenden Produkts, welches praktisch keine Wartung benötigt. Zudem konnten wir den Auftrag dank des dringend benötigten Bedarfs vorziehen. Vielen Dank für die professionelle und zuverlässige Arbeit!</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="{{company.baseUrlForAssets}}/img/people/reka-marco.png" alt="client">
                    <h3>Marco Reka</h3>
                    <span>Head of Reka Tattoo</span>
                </div>
                <p>Wir waren begeistert von der professionellen und sehr guten Leistung von Datenpfleger. Ihr Team hat unsere spezifischen Anforderungen perfekt verstanden und die Lösung sehr schnell und effizient umgesetzt. Wir können Datenpflege uneingeschränkt empfehlen!</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="{{company.baseUrlForAssets}}/img/people/zigova-livia.png" alt="client">
                    <h3>Livia Zigova</h3>
                    <span>HR of Business Center</span>
                </div>
                <p>Dank Datenpflege konnten wir ein separates Wi-Fi-Netzwerk für Gäste und Kunden einrichten, das unseren Anforderungen entspricht. Ihre Arbeit im Bereich des Anforderungsmanagements war ausgezeichnet und sie haben unsere Wünsche perfekt umgesetzt. Zudem haben sie uns auch geholfen, die laufenden Kosten zu optimieren. Wir sind sehr zufrieden mit ihrer professionellen Leistung.</p>
            </div>

        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->
