<div class="overview-box">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 overview-img">
            <img src="{{company.baseUrlForAssets}}{{solution1.urlTitlePicture}}" alt="image">
        </div>

        <div class="col-lg-6 col-md-6 overview-content">
            <div class="icon">
                <i class="{{solution1.iconUrl}}"></i>
            </div>
            <h3>{{solution1.title}}</h3>
            <p>{{solution1.subtitle}}</p>
            <ul>
                <li *ngFor="let include of solution1.includes">{{include.title}}</li>
            </ul>
            <a routerLink="{{solution1.routerLink}}" class="btn btn-primary">Zur Dienstleistung</a>
        </div>
    </div>
</div>

<ng-container *ngIf="solution2">
    <div class="overview-box">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6 overview-content">
                <div class="icon">
                    <i class="{{solution2.iconUrl}}"></i>
                </div>
                <h3>{{solution2.title}}</h3>
                <p>{{solution2.subtitle}}</p>
                <ul>
                    <li *ngFor="let include of solution2.includes">{{include.title}}</li>
                </ul>
                <a routerLink="{{solution2.routerLink}}" class="btn btn-primary">Zur Dienstleistung</a>

            </div>

            <div class="col-lg-6 col-md-6 overview-img">
                <img src="{{company.baseUrlForAssets}}{{solution2.urlTitlePicture}}" alt="image">
            </div>
        </div>
        <br>
    </div>
</ng-container>
