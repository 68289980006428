import {Component, Input} from '@angular/core';
import {Company} from '../../../models/company';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-saas-main-banner',
  templateUrl: './saas-main-banner.component.html',
  styleUrls: ['./saas-main-banner.component.scss']
})
export class SaasMainBannerComponent {
    @Input() company: Company;
}
