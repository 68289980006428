<!-- Start Join Area -->
<section class="join-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="join-img">
                    <img src="{{company.baseUrlForAssets}}/img/3.png" alt="img">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="join-content">
                    <span>Start 30 Days Free Trial</span>
                    <h3>Overall 400k+ Our Clients! Please Get Start Now</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                    <a routerLink="/contact-us" class="btn btn-primary">Join Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Join Area -->
