import {Component, Input} from '@angular/core';
import {Company} from '../../../models/company';
import {WorkProcess} from '../../../models/solution';

@Component({
  selector: 'app-work-process-area',
  templateUrl: './work-process-area.component.html',
  styleUrls: ['./work-process-area.component.scss']
})
export class WorkProcessAreaComponent {
    @Input() company: Company;
    @Input() workProcess: WorkProcess[];
}
