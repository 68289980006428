<ng-container *ngIf="(company$ | async) as company; else loading">
    <!-- Start Page Title Area -->
    <section class="page-title-banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Dienstleistungen</h2>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
        <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
        <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
        <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
        <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
        <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>

    </section>
    <!-- End Page Title Area -->

    <!-- Start Services Area -->
    <section class="services-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Testimonials</h2>
                <div class="bar"></div>
                <p>Wir betreuen unsere Kunden in jede Hinsicht</p>
            </div>
            <div class="row">

                <div class="col-lg-4 col-md-6" *ngFor="let solution of solutions">
                    <div class="single-services" [routerLink]="['/' + solution.routerLink]">
                        <div class="icon">
                            <i class="{{solution.iconUrl}}"></i>
                        </div>
                        <h3>{{solution.title}}</h3>
                        <p>{{solution.subtitle}}.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Services Area -->
</ng-container>

<ng-template #loading>Loading...</ng-template>
