<ng-container *ngIf="(company$ | async) as company; else loading">
    <ng-container *ngIf="(blogs$ | async) as blogs">

        <!-- Start Page Title Area -->
        <section class="page-title-banner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>Our Latest Blog</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
            <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
            <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
            <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
            <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
            <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>

        </section>
        <!-- End Page Title Area -->

        <!-- Start Blog Area -->
        <section class="blog-area blog-section ptb-100">
            <div class="container">
                <div class="row">

                    <ng-container *ngFor="let blog of blogs">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-blog-post">
                                <a routerLink="/blog-details/{{blog.idName}}" class="post-image"><img
                                    src="{{company.baseUrlForAssets}}{{blog.pathTitleImage}}" alt="blog-image"></a>

                                <div class="blog-post-content">
                                    <ul>
                                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">{{blog.author}}</a></li>
                                        <li><i class="icofont-wall-clock"></i>{{ blog.date | date:'dd MMMM yyyy' }}</li>

                                    </ul>
                                    <h3><a routerLink="/blog-details/{{blog.idName}}">{{blog.title}}</a></h3>
                                    <p>{{blog.subtitle}}</p>
                                    <a routerLink="/blog-details/{{blog.idName}}" class="read-more-btn">Mehr <i
                                        class="icofont-rounded-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-container>


                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" routerLink="/blog"><i
                                        class="icofont-double-left"></i></a></li>
                                    <li class="page-item active"><a class="page-link" routerLink="/blog">1</a></li>
                                    <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                                    <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                                    <li class="page-item"><a class="page-link" routerLink="/blog"><i
                                        class="icofont-double-right"></i></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>


                </div>
            </div>
        </section>
        <!-- End Blog Area -->
    </ng-container>
</ng-container>

<ng-template #loading>Loading...</ng-template>
