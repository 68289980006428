<!-- Start SaaS Main Banner -->
<div class="main-banner saas-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">

                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/arrow.png"
                                 class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s"
                                 alt="arrow">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/box1.png"
                                 class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="box1">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/boy1.png"
                                 class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="boy1">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/boy2.png"
                                 class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="boy2">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/boy3.png"
                                 class="wow animate__animated animate__bounceIn" data-wow-delay="0.6s" alt="boy3">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/digital-screen.png"
                                 class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s"
                                 alt="digital-screen">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/filter1.png"
                                 class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="filter1">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/filter2.png"
                                 class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s"
                                 alt="filter2">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/filter3.png"
                                 class="wow animate__animated animate__rotateIn" data-wow-delay="0.6s"
                                 alt="filter3">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/girl1.png"
                                 class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="girl1">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/girl2.png"
                                 class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="girl2">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/monitor.png"
                                 class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="monitor">
                            <img src="{{company.baseUrlForAssets}}/img/saas-shape/main-image.png"
                                 class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s"
                                 alt="main-image.png">

                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">

                            <h1>{{company.slogan}}</h1>
                            <p>{{company.sloganLang}}</p>

                            <a routerLink="/contact-us" class="btn btn-primary">Chat mit Uns</a>
                            <!--<a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-gray shape-1"></div>

    <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="shape4 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape4.png" alt="img"></div>

</div>
<!-- End SaaS Main Banner -->
