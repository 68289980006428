<!-- Start Overview Area -->
<section class="overview-section ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Unseren Top Dienstleitungen</h2>
            <div class="bar"></div>
            <p>Wir sind Ihr persönlicher IT Partner!</p>
        </div>

        <ng-container *ngFor="let group of groupedSolutionBoxes">
            <app-overview-box [solution1]="group[0]" [solution2]="group[1] ? group[1] : null" [company]="company"></app-overview-box>

        </ng-container>
    </div>

    <div class="shape7"><img src="{{company.baseUrlForAssets}}/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="{{company.baseUrlForAssets}}/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->
