import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DataService} from '../../../services/data/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Variant} from '../../../models/variant';
import {Solution} from '../../../models/solution';
import {Company} from '../../../models/company';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';


@Component({
    selector: 'app-product-landing',
    templateUrl: './product-landing.component.html',
    styleUrls: ['./product-landing.component.scss'],
    providers: [DataService]
})
export class ProductLandingComponent implements OnInit, OnDestroy {
    solution$: BehaviorSubject<Solution | undefined> = new BehaviorSubject<Solution | undefined>(undefined);
    solutionVariants: Variant[];
    @Input() company: Company;
    private ngUnsubscribe = new Subject<void>();

    constructor(
        private titleService: Title,
        private route: ActivatedRoute,
        private router: Router,
        private data: DataService,
        // google keywords
        private meta: Meta
    ) {
        // Kannst du mir hier solution.title einfügen?
        this.titleService.setTitle('Datenpfleger');
    }

    ngOnInit() {
        this.data.loadCompany().pipe(takeUntil(this.ngUnsubscribe)).subscribe(company => {
            this.company = company;
        });

        const navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras && navigation.extras.state) {
            this.solution$.next(navigation.extras.state.solution);
            this.loadSolutionVariant(navigation.extras.state.solution.idName);
            this.solution$.subscribe(solution => {
                if (solution) {
                    this.titleService.setTitle(solution.title);
                    // google keywords
                    this.meta.updateTag({ name: 'keywords', content: solution.keywords });
                    this.meta.updateTag({ name: 'description', content: solution.subtitle });
                }
            });
        } else {
            this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
                const idNameSolution = params.idName;
                this.data.loadSolutionByIdName(idNameSolution).pipe(takeUntil(this.ngUnsubscribe)).subscribe(solution => {
                    this.solution$.next(solution);
                    if (solution) {
                        this.titleService.setTitle(solution.title);
                        // google keywords
                        this.meta.addTag({ name: 'keywords', content: solution.keywords });
                    }
                });
            });
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private loadSolutionVariant(idName: string) {
        this.data.loadSolutionVariant(idName).pipe(
            takeUntil(this.ngUnsubscribe),
            catchError(err => {
                // handle error here, for example log it and return an empty array
                console.error(err);
                return of([]);
            })
        ).subscribe(variant => this.solutionVariants = variant);
    }
}
