import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Company} from '../../../models/company';
import {DataService} from '../../../services/data/data.service';

@Component({
    selector: 'app-digital-marketing',
    templateUrl: './digital-marketing.component.html',
    styleUrls: ['./digital-marketing.component.scss']
})
export class DigitalMarketingComponent implements OnInit {
    company: Company;

    constructor( private titleService: Title,
                 private data: DataService) {
        this.titleService.setTitle('Datenpfleger - Marketing');
    }

    ngOnInit() {
        this.data.loadCompany().subscribe(company => {
            this.company = company;
        });
    }

}
