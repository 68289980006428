import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Solution} from '../../../models/solution';
import {Variant} from '../../../models/variant';
import {Company} from '../../../models/company';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../services/data/data.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

    solution: Solution | undefined;
    solutionVariants: Variant[];
    company: Company;

    constructor( private titleService: Title,
                 private route: ActivatedRoute,
                 private data: DataService) {
        this.titleService.setTitle('Datenpfleger');
    }

    ngOnInit() {
        this.data.loadCompany().subscribe(company => {
            this.company = company;
        });
        this.route.params.subscribe(params => {
            const idNameSolution = params.idName;
            this.data.loadSolutionByIdName(idNameSolution).subscribe(solution =>
                this.solution = solution);
            this.data.loadSolutionVariant(idNameSolution).subscribe(variant =>
                this.solutionVariants = variant);
        });
    }
}
