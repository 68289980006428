import {Component, Input} from '@angular/core';
import {Company} from '../../../models/company';
import {Solution} from '../../../models/solution';
import { Router, NavigationExtras } from '@angular/router';


@Component({
  selector: 'app-overview-box',
  templateUrl: './overview-box.component.html',
  styleUrls: ['./overview-box.component.scss']
})
export class OverviewBoxComponent {
    @Input() company: Company;
    @Input() groupSolution: Solution[];
    @Input() solution1: Solution;
    @Input() solution2: Solution;

}
