import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Company } from '../../../models/company';
import { Solution } from '../../../models/solution';

@Component({
    selector: 'app-overview-arena',
    templateUrl: './overview-arena.component.html',
    styleUrls: ['./overview-arena.component.scss']
})
export class OverviewArenaComponent implements OnChanges {
    @Input() company: Company;
    @Input() solutions: Solution[];
    groupedSolutionBoxes: Array<Solution[]> = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.solutions) {
            this.groupedSolutionBoxes = this.getGroupedSolutionBoxes();
        }
    }

    private getGroupedSolutionBoxes(): Array<Solution[]> {
        const groupedBoxes = [];
        let row = [];
        for (let i = 0; i < this.solutions.length; i++) {
            const box = this.solutions[i];
            row.push(box);
            if (row.length === 2 || i === this.solutions.length - 1 && row.length > 0) {
                groupedBoxes.push(row);
                row = [];
            }
        }
        return groupedBoxes;
    }

}
