import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { Company } from '../../../models/company';
import { DataService } from '../../../services/data/data.service';
import { TagService } from '../../../services/tag/tag.service';
import { Blog } from '../../../models/blog';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
    company$: Observable<Company>;
    blogs$: Observable<Blog[]>;

    constructor(
        private titleService: Title,
        private route: ActivatedRoute,
        private data: DataService,
        private tagService: TagService,
    ) {
        this.titleService.setTitle('Blog');
    }

    ngOnInit() {
        this.company$ = this.data.loadCompany();
        this.blogs$ = combineLatest([
            this.data.loadBlogs(),
            this.route.queryParamMap
        ]).pipe(
            map(([blogs, params]) => {
                const tag = params.get('tag');
                const category = params.get('category');

                return blogs.filter(blog =>
                    (!tag || blog.tags.includes(tag)) &&
                    (!category || blog.categories.includes(category))
                );
            })
        );

        this.blogs$.subscribe(blogs => {
            for (const blog of blogs) {
                console.log(blog.title);
            }
        });
    }
}
