<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Error</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Error Area -->
<section class="error-area ptb-100">
    <div class="container">
        <div class="notfound">
            <div class="notfound-404"></div>
            <h2>404</h2>
            <span>Oops!! Hier ist etwas schief gelaufen</span>
            <p>Sorry diese Seite ist nicht verfügbar</p>
            <a href="/" class="btn btn-primary">Zurück zu Home</a>
        </div>
    </div>
</section>
<!-- End Error Area -->
