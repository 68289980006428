import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
    //private _baseDatabaseUrl: string = 'http://localhost:8082/web/company/1';
    private _baseDatabaseUrl: string = 'https://zh3.api.linushosting.ch/web/company/1';
    private _databaseBlogs: string = '/posts';
    private _databaseBlog: string = '/post';
    private _dbSolutionName: string = '/solution';
    private _databaseService: string = '/solutions';

    private _databaseSearch: string = this._baseDatabaseUrl + '/search';
    private _databaseCompany: string = '/company';
    private _databaseProject: string = '/project';
    private _databaseEmployee: string = '/employee';
    private _databaseCustomer: string = '/customer-review';
    private _databaseVarianSolution: string = '/variant-solution';
    private _databaseTags: string = '/tags';
    private _databaseWebpages: string = '/webpages';


  constructor() { }

    debugLog(message: any) {
        if (!environment.production) {
            console.log(message);
        }
    }

    get baseDatabaseUrl(): string {
        const url = this._baseDatabaseUrl;
        this.debugLog(url);
        return url;
    }

    get companyInfoUrl(): string {
        const url = this._baseDatabaseUrl + this._databaseCompany;
        this.debugLog(url);
        return url;
    }

    get solutionsUrl(): string {
        const url = this._baseDatabaseUrl + this._databaseService;
        this.debugLog(url);
        return this._baseDatabaseUrl + this._databaseService;
    }


    get projectsUrl(): string {
        const url = this._baseDatabaseUrl + this._databaseProject;
        this.debugLog(url);
        return url;
    }

    get blogsUrl(): string {
        const url = this._baseDatabaseUrl + this._databaseBlogs;
        this.debugLog(url);
        return url;
    }

    get blogUrl(): string {
        const url = this._baseDatabaseUrl + this._databaseBlog;
        this.debugLog(url);
        return url;
    }

    get employeeUrl(): string {
        return this._baseDatabaseUrl + this._databaseEmployee;
    }

    get customerUrl(): string {
        return this._baseDatabaseUrl + this._databaseCustomer;
    }

    get searchSolutionWithIdNameUrl(): string {
        const url = this._baseDatabaseUrl + this._dbSolutionName + '/';
        this.debugLog(url);
        return url;
    }

    get searchSolutionVariant(): string{
        return this._databaseSearch + this._dbSolutionName + '/';
    }

    get searchAllWebpages(): string{
        return this._baseDatabaseUrl + this._databaseWebpages;
    }

}
