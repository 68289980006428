import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SaasLandingComponent} from './components/pages/saas-landing/saas-landing.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {FooterComponent} from './components/layout/footer/footer.component';
import {SidebarComponent} from './components/layout/sidebar/sidebar.component';
import {FunfactComponent} from './components/common/funfact/funfact.component';
import {PartnerComponent} from './components/common/partner/partner.component';
import {AppLandingComponent} from './components/pages/app-landing/app-landing.component';
import {DigitalMarketingComponent} from './components/pages/digital-marketing/digital-marketing.component';
import {ProductLandingComponent} from './components/pages/product-landing/product-landing.component';
import {SoftwareLandingComponent} from './components/pages/software-landing/software-landing.component';
import {BookLandingComponent} from './components/pages/book-landing/book-landing.component';
import {StartupAgencyComponent} from './components/pages/startup-agency/startup-agency.component';
import {PaymentProcessingComponent} from './components/pages/payment-processing/payment-processing.component';
import {AboutUsComponent} from './components/pages/about-us/about-us.component';
import {FeaturesComponent} from './components/pages/features/features.component';
import {ServicesComponent} from './components/pages/services/services.component';
import {GalleryComponent} from './components/pages/gallery/gallery.component';
import {BlogComponent} from './components/pages/blog/blog.component';
import {BlogDetailsComponent} from './components/pages/blog-details/blog-details.component';
import {ContactUsComponent} from './components/pages/contact-us/contact-us.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {ComingSoonComponent} from './components/pages/coming-soon/coming-soon.component';
import {LoginComponent} from './components/pages/login/login.component';
import {SignupComponent} from './components/pages/signup/signup.component';
import {TeamComponent} from './components/pages/team/team.component';
import {PricingComponent} from './components/pages/pricing/pricing.component';
import {ProjectsComponent} from './components/pages/projects/projects.component';
import {HttpClientModule} from '@angular/common/http';
import {SaasMainBannerComponent} from './components/shared/saas-main-banner/saas-main-banner.component';
import {FeaturesAreaComponent} from './components/shared/features-area/features-area.component';
import {OverviewArenaComponent} from './components/shared/overview-arena/overview-arena.component';
import {JoinAreaComponent} from './components/shared/join-area/join-area.component';
import {CtaAreaComponent} from './components/shared/cta-area/cta-area.component';
import {SaasToolsAreaComponent} from './components/shared/saas-tools-area/saas-tools-area.component';
import {WorkProcessAreaComponent} from './components/shared/work-process-area/work-process-area.component';
import {PriceBoxComponent} from './components/shared/price-box/price-box.component';
import {FeedbackAreaComponent} from './components/shared/feedback-area/feedback-area.component';
import {PlatformConnectComponent} from './components/shared/platform-connect/platform-connect.component';
import {OverviewBoxComponent} from './components/shared/overview-box/overview-box.component';
import {GetStartedComponent} from './components/shared/get-started/get-started.component';
import {WebpageComponent} from './components/pages/webpage/webpage.component';
import {UniquePipe} from './pipes/unique/unique.pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import {SingelWebpageComponent} from './components/shared/singel-webpage/singel-webpage.component';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {OrderByPipe} from './pipes/orderBy/order-by.pipe';
import {ImpressumComponent} from './components/pages/impressum/impressum.component';
registerLocaleData(localeDe);


@NgModule({
    declarations: [
        AppComponent,
        SaasLandingComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        FunfactComponent,
        PartnerComponent,
        AppLandingComponent,
        DigitalMarketingComponent,
        ProductLandingComponent,
        SoftwareLandingComponent,
        BookLandingComponent,
        StartupAgencyComponent,
        PaymentProcessingComponent,
        AboutUsComponent,
        FeaturesComponent,
        ServicesComponent,
        GalleryComponent,
        BlogComponent,
        BlogDetailsComponent,
        ContactUsComponent,
        NotFoundComponent,
        FaqComponent,
        ComingSoonComponent,
        LoginComponent,
        SignupComponent,
        TeamComponent,
        PricingComponent,
        ProjectsComponent,
        SaasMainBannerComponent,
        FeaturesAreaComponent,
        OverviewArenaComponent,
        JoinAreaComponent,
        CtaAreaComponent,
        SaasToolsAreaComponent,
        WorkProcessAreaComponent,
        PriceBoxComponent,
        FeedbackAreaComponent,
        PlatformConnectComponent,
        OverviewBoxComponent,
        GetStartedComponent,
        WebpageComponent,
        UniquePipe,
        SingelWebpageComponent,
        PartnerComponent,
        OrderByPipe,
        ImpressumComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        AppRoutingModule,
        HttpClientModule,
        NgxPaginationModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
