export class Solution {
    id: number;
    about: string[];
    content: string[];
    iconUrl: string;
    idName: string;
    onMainPage: boolean;
    rating: number;
    routerLink: string;
    subtitle: string;
    text: string;
    title: string;
    urlTitlePicture: string;
    includes: Includes[];
    workProcess: WorkProcess[];
    keywords: string;
}
export class SolutionImpl implements Solution {
    id: number;
    idName: string;
    title: string;
    urlCoverPicture: string;
    urlSmallPicture: string;
    urlTitlePicture: string;
    rating: number;
    subtitle: string;
    iconUrl: string;
    text: string;
    routerLink: string;
    content: string[];
    about: string[];
    includes: Includes[];
    workProcess: WorkProcess[];
    onMainPage: boolean;
    tagsIdName: string[];
    keywords: string;

    static fromJson(json: any): Solution {
        const solution = new Solution();
        solution.id = json.id;
        solution.idName = json.idName;
        solution.title = json.title;
        solution.urlTitlePicture = json.urlTitlePicture;
        solution.rating = json.rating;
        solution.subtitle = json.subtitle;
        solution.iconUrl = json.iconUrl;
        solution.text = json.text;
        solution.routerLink = json.routerLink;
        solution.onMainPage = json.onMainPage;
        solution.content = JSON.parse(json.content || '[]');
        solution.about = JSON.parse(json.about || '[]');
        solution.includes = json.includes.map((include: any) => {
            const inc = new Includes();
            inc.id = include.id;
            inc.icon = include.icon;
            inc.title = include.title;
            inc.text = include.text;
            inc.sortingNumber = include.sortingNumber;
            return inc;
        });
        solution.workProcess = json.workProcesses.map((process: any) => {
            const wp = new WorkProcess();
            wp.id = process.id;
            wp.icon = process.icon;
            wp.title = process.title;
            wp.text = process.text;
            wp.sortingNumber = process.sortingNumber;
            return wp;
        });
        solution.keywords = json.keywords;
        return solution;
    }

}

export class Includes {
    id: number;
    icon: string;
    title: string;
    text: string;
    sortingNumber: number;
}

export class WorkProcess {
    id: number;
    icon: string;
    title: string;
    text: string;
    sortingNumber: number;
}
