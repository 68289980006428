import {Component, Input, SimpleChanges} from '@angular/core';
import { Company } from '../../../models/company';

@Component({
    selector: 'app-features-area',
    templateUrl: './features-area.component.html',
    styleUrls: ['./features-area.component.scss']
})
export class FeaturesAreaComponent {
    @Input() company: Company;

    ngOnChanges() {
    }

}
