export class Customer{
    customerName: string;
    jobTitle: string;
    customerUrlPicture: string;
    rating: string;
    review: string;
    service: string;
    company: string;
    companyUrl: string;
    companyUrlPng: string;

}

export class CustomerImpl{
    customerName: string;
    jobTitle: string;
    customerUrlPicture: string;
    rating: string;
    review: string;
    service: string;
    company: string;
    companyUrl: string;
    companyUrlPng: string;

    static fromJson(json: any): Customer {
        const review = new Customer();
        review.customerName = json.customerName;
        review.jobTitle = json.jobTitle;
        review.customerUrlPicture = json.customerUrlPicture;
        review.rating = json.rating;
        review.review = json.review;
        review.service = json.service;
        review.company = json.company;
        review.companyUrl = json.companyUrl;
        review.companyUrlPng = json.companyUrlPng;
        return review;
    }

}

