import { Component } from '@angular/core';

@Component({
  selector: 'app-singel-webpage',
  templateUrl: './singel-webpage.component.html',
  styleUrls: ['./singel-webpage.component.scss']
})
export class SingelWebpageComponent {

}
