import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    private selectedTag = new BehaviorSubject<string>(null);
    selectedTag$ = this.selectedTag.asObservable();

    constructor() { }

    setSelectedTag(tagIdName: string) {
        this.selectedTag.next(tagIdName);
    }
}
