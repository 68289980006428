<ng-container *ngIf="(company$ | async) as company; else loading">
    <!-- Start Page Title Area -->
    <section class="page-title-banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Impressum</h2>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
        <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
        <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
        <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
        <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
        <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>

    </section>
</ng-container>


<div class="impressum-area">
    <div class="container">
        <div class="impressum">
            <h2>Kontaktadresse</h2>
            <p>Datenpfleger GmbH<br>
                Badenerstrasse 414<br>
                8004 Zürich<br>
                Schweiz<br>
                info@datenpfleger.ch</p>
            <h2>Vertretungsberechtigte Personen</h2>
            <p>Linus Gobita</p>
            <h2>Handelsregistereintrag</h2>
            <h5>Eingetragener Firmenname:<br></h5>
            <p> Datenpfleger GmbH<br>
                Datenpfleger Sàrl<br>
                Datenpfleger Ltd liab Co<br>
                Datenpfleger Sagl<br>
                Nummer: CH-020.4.079.411-6<br>
                UID/MWST: CHE-432.076.239<br>
                Handelsregisteramt: Zürich</p>
            <h2>Haftungsausschluss</h2>
            <p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
                Zuverlässigkeit und Vollständigkeit der Informationen.</p>
            <p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem
                Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der
                Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.</p>
            <p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das
                gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
                Veröffentlichung zeitweise oder endgültig einzustellen.</p>
            <h2>Haftung für Links</h2>
            <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs Es wird
                jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
                erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.</p>
            <h2>Urheberrechte</h2>
            <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website
                gehören ausschliesslich <strong>der Firma Datenpfleger GmbH</strong> oder den speziell
                genannten Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der
                Urheberrechtsträger im Voraus einzuholen.</p>
            <h2>Datenschutz</h2>
            <p>Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen
                des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf
                Schutz vor Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein. Persönliche Daten
                werden streng vertraulich behandelt und weder an Dritte verkauft noch weiter gegeben.</p>
            <p>In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich
                vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.</p>
            <p>Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles gespeichert: IP-Adresse, Datum,
                Uhrzeit, Browser-Anfrage und allg. übertragene Informationen zum Betriebssystem resp. Browser. Diese
                Nutzungsdaten bilden die Basis für statistische, anonyme Auswertungen, so dass Trends erkennbar sind,
                anhand derer wir unsere Angebote entsprechend verbessern können.</p>
            <h2>Datenschutzerklärung für die Nutzung von Google Analytics</h2>
            <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. («Google»). Google
                Analytics verwendet sog. «Cookies», Textdateien, die auf Ihrem Computer gespeichert werden und die eine
                Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
                über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen
                und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre
                IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
                Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.</p>
            <p>Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort
                gekürzt. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
                über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese
                Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
                Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen von Google Analytics von Ihrem
                Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
            <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software
                verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie
                sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und
                Weise und zu dem zuvor benannten Zweck einverstanden.</p>
            <p style="visibility: hidden;">
            </p></div>
    </div>
    <div class="fusion-clearfix"></div>

</div>


<!-- End Page Title Area -->
<ng-template #loading>Loading...</ng-template>
