export class Webpage {
    id: number;
    name: string;
    url: string;
    imgUrl: string;
    domain: string;
    layout: string;
}

export class WebpageImpl implements Webpage {
    id: number;
    name: string;
    url: string;
    imgUrl: string;
    domain: string;
    layout: string;


    static fromJson(json: any): Webpage {
        const webpage = new Webpage();
        webpage.id = json.id;
        webpage.name = json.name;
        webpage.imgUrl = json.imgUrl;
        webpage.url = json.url;
        webpage.domain = json.domain;
        webpage.layout = json.layout;
        return webpage;
    }

}
