import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Company} from '../../../models/company';
import {DataService} from '../../../services/data/data.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
    company: Company;
    constructor( private titleService: Title,
                 private data: DataService) {
        this.titleService.setTitle('404');
    }

    ngOnInit() {
        this.data.loadCompany().subscribe(company => {
            this.company = company;
        });
    }
}
