<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Nicht gefunden, was Sie suchen? Wir bieten noch mehr!</p>
            </div>

            <div class="col-lg-5 col-md-5 text-end">
                <a routerLink="/service" class="btn btn-primary">Zu allen Dienstleistunden</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->
