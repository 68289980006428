<ng-container *ngIf="(company$ | async) as company; else loading">
    <app-saas-main-banner [company]="company"></app-saas-main-banner>
    <ng-container *ngIf="(solutionsOnHome$ | async) as solutionsOnHome">
        <app-overview-arena [company]="company" [solutions]="solutionsOnHome"></app-overview-arena>
        <app-cta-area></app-cta-area>
        <app-saas-tools-area [company]="company"></app-saas-tools-area>
        <app-features-area [company]="company"></app-features-area>
        <!--<app-feedback-area></app-feedback-area>-->
        <!--<app-partner></app-partner>-->
    </ng-container>
</ng-container>

<ng-template #loading>Loading...</ng-template>
