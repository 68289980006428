<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Kontaktiere uns</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="{{company.baseUrlForAssets}}/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="{{company.baseUrlForAssets}}/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Contact Info Area -->
<section class="contact-info-area ptb-100">
    <!-- Hier ist das iFrame -->
    <!--    <div class="row justify-content-center">
           <div class="col-lg-8">
               <div style="display: flex; justify-content: center;">
                   <iframe src="https://datenpfleger.ch/hub/index.php/apps/appointments/embed/mhnbw4v9DmAC/form" width="100%" height="800" frameborder="0" style="border:0; max-width: 800px;" allowfullscreen></iframe>
               </div>
           </div>
       </div>-->

    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-email"></i>
                    </div>
                    <h3>Maile Uns</h3>
                    <p><a href="mailto:{{company.infoMail}}">{{company.infoMail}}</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-google-map"></i>
                    </div>
                    <h3>Besuche uns</h3>
                    <p>{{company.street}}, {{company.plz}} {{company.city}}</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-phone"></i>
                    </div>
                    <h3>Ruf Uns An</h3>
                    <p><a href="tel:{{company.phone}}">{{company.phone}}</a></p>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- End Contact Info Area -->
