
    <!-- Start Features Area -->
    <section class="features-area saas-features ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Was bringt Ihrem Unternehmen eine Digitalisierung?</h2>
                <div class="bar"></div>
                <p>Die Digitalisierung bringt zahlreiche Vorteile für Ihr Unternehmen - hier sind fünf Gründe, warum Sie
                    auf den Zug aufspringen sollten.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="icofont-money"></i>
                        </div>
                        <h3>Kostenreduzierung</h3>
                        <p>Digitale Technologien können helfen, Kosten zu senken, indem sie manuelle und papierbasierte
                            Prozesse durch automatisierte Lösungen ersetzen.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="icofont-business-man"></i>
                        </div>
                        <h3>Kundenbindung</h3>
                        <p>ihre Kunden besser zu verstehen und ihnen personalisierte Dienstleistungen und Produkte
                            anzubieten.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="icofont-people"></i>
                        </div>
                        <h3>Personalisierung</h3>
                        <p>Durch die Nutzung von Daten können Unternehmen das Verhalten ihrer Kunden besser verstehen
                            und ihre Angebote entsprechend anpassen.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="icofont-ui-check"></i>
                        </div>
                        <h3>Personalisierung</h3>
                        <p>Digitale Tools und Systeme können ihre repetitiven und zeitaufwändigen Aufgaben übernehmen,
                            was die Produktivität steigert und die Fehlerquote verringert.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="icofont-responsive"></i>
                        </div>
                        <h3>Flexibilität</h3>
                        <p>Durch die Nutzung von Cloud-basierten Tools und Systemen können Ihre Mitarbeiter von überall
                            aus arbeiten, was die Work-Life-Balance verbessert und die Arbeitsbedingungen flexibler
                            gestaltet.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="icofont-tree"></i>
                        </div>
                        <h3>Nachhaltigkeit</h3>
                        <p>Digitale Technologien können Unternehmen helfen, nachhaltiger zu arbeiten. Zum Beispiel
                            können digitale Tools und Systeme dazu beitragen, den Energieverbrauch und Papierverbrauch
                            zu reduzieren.</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="shape7"><img src="{{company.baseUrlForAssets}}/img/shape7.png" alt="shape"></div>
        <div class="shape3"><img src="{{company.baseUrlForAssets}}/img/shape3.png" alt="img"></div>
        <div class="bg-gray shape-1"></div>
        <div class="shape6"><img src="{{company.baseUrlForAssets}}/img/shape6.png" alt="img"></div>
        <div class="shape8 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape8.svg" alt="shape"></div>
        <div class="shape9"><img src="{{company.baseUrlForAssets}}/img/shape9.svg" alt="shape"></div>
        <div class="shape10"><img src="{{company.baseUrlForAssets}}/img/shape10.svg" alt="shape"></div>
        <div class="shape11 rotateme"><img src="{{company.baseUrlForAssets}}/img/shape11.svg" alt="shape"></div>
    </section>
    <!-- End Features Area -->

