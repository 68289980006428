<!-- Start Pricing Area -->
<section class="pricing-area ptb-100">
    <div class="container">
        <div class="row">


            <div class="col-lg-4 col-md-6" *ngFor="let variant of solutionVariants">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">CHF</span>{{variant.price}}
                    </div>
                    <span class="month">{{variant.priceCharacteristics}}</span>
                    <div class="pricingHeader">
                        <h3 class="title">{{variant.title}}</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <ul>
                                <li class="active" *ngFor="let content of variant.content"><i class='bx bx-check'></i> {{content}}</li>
                            </ul>
                        </ul>
                    </div>
                    <a routerLink="/" class="btn btn-primary">Anfragen</a>
                </div>
            </div>


        </div>

    </div>
</section>
<!-- End Pricing Area -->
