import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../../services/data/data.service';
import { Company } from '../../../models/company';
import { Solution } from '../../../models/solution';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-saas-landing',
    templateUrl: './saas-landing.component.html',
    styleUrls: ['./saas-landing.component.scss'],
    providers: [DataService]
})
export class SaasLandingComponent implements OnInit {

    company$: Observable<Company>;
    solutionsOnHome$: Observable<Solution[]>;

    constructor(
        private titleService: Title,
        private data: DataService,
    ) {
        this.titleService.setTitle('Datenpfleger');
    }

    ngOnInit() {
        this.loadSolutions();
        this.company$ = this.data.loadCompany();
    }

    loadSolutions() {
        this.solutionsOnHome$ = this.data.loadSolutions()
            .pipe(
                map(solutions => solutions.filter(solution => solution.onMainPage === true))
            );
    }
}
