export class Blog {
    idPost: number;
    idName: string;
    title: string;
    subtitle: string;
    pathTitleImage: string;
    author: string;
    date: Date;
    tags: string[];
    categories: string[];
    postSections: PostSection[];
    postCategory: PostSection[];
    keywords: string;
}

export class PostSection {
    idPost: number;
    idName: string;
    idSection: number;
    sectionType: string;
    textContent: string;
    orderNo: number;
    keywords: string;
    mediaPath: string;
}

export class PostCategory {
    idCategory: number;
    categoryName: string;
}


export class BlogImpl {
    idPost: number;
    idName: string;
    title: string;
    subtitle: string;
    pathTitleImage: string;
    author: string;
    date: Date;
    tags: string[];
    postSections: PostSection[];
    postCategory: PostCategory[];
    mediaPath: string;
    keywords: string;

    static fromJson(json: any): Blog {
        const blogPost = new Blog();
        blogPost.idPost = json.idPost;
        blogPost.idName = json.idName;
        blogPost.title = json.title;
        blogPost.subtitle = json.subtitle;
        blogPost.pathTitleImage = json.pathTitleImage;
        blogPost.author = json.person.firstName + ' ' + json.person.name;
        blogPost.date = new Date(json.publicationDate);
        blogPost.tags = json.postTags.map(tagObj => tagObj.tag.tagName);
        blogPost.postSections = json.postSections.map(section => {
            const postSection = new PostSection();
            postSection.idSection = section.idSection;
            postSection.sectionType = section.sectionType;
            postSection.textContent = section.textContent;
            postSection.orderNo = section.orderNo;
            postSection.mediaPath = section.mediaPath;
            return postSection;
        });
        blogPost.categories = json.postCategories.map(categoryObj => categoryObj.category.categoryName);
        blogPost.postCategory = json.postCategories.map(category => {
            const postCategory = new PostCategory();
            postCategory.idCategory = category.idCategory;
            postCategory.categoryName = category.categoryName;
            return postCategory;
        });
        blogPost.keywords = json.keywords;
        return blogPost;
    }
}
